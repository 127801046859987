export const dataSectionsEn = [
  {
    sectionDescriptionId: "Nosotros",
    sectionDescriptionText: "The only marina in the Acapulco Diamante area",
    distribution: "normal",
    title: "Marina Cabo Marqués",
    content: [
      {
        tag: "p",
        content: "Located in the most exclusive area of Acapulco, it offers shelter against hurricanes and modern services with the most up-to-date equipment."
      },
      {
        tag: "p",
        content: "The Marina Cabo Marqués is part of the most exclusive resort in the region, along with Acapulco’s number one hotel <strong>Banyan Tree Cabo Marqués</strong>, located in the safest and most private residential complex, Los Riscos.</strong>"
      }
    ],
    images: [
      "/images/fotos-marina/2-nosotros/nosotros-1.jpg",
      "/images/fotos-marina/2-nosotros/nosotros-2.jpg",
      "/images/fotos-marina/2-nosotros/nosotros-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Beneficios",
    sectionDescriptionText: "Enjoy all the benefits of being a member of the Marina",
    distribution: "inverse",
    title: "Benefits",
    content: [
      {
        tag: "ul",
        content: [
          "The ideal location: Acapulco Diamante Zone",
          "The most modern marina in the Mexican Pacific",
          "We receive boats up to 150 feet in length",
          "It´s topography protects us from adverse weather conditions",
          "Floating wave attenuator and breakwater sheet piles ensure the safety of your vessel",
          "Services are offered 24 hours a day, 365 days a year"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/3-beneficios/beneficios-1.jpg",
      "/images/fotos-marina/3-beneficios/beneficios-2.jpg",
      "/images/fotos-marina/3-beneficios/beneficios-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Nautica",
    sectionDescriptionText: "The top nautical experience in Acapulco",
    distribution: "normal",
    title: "Nautical Marina",
    content: [
      {
        tag: "ul",
        content: [
          "118 tie-down positions (slips) on 6 floating docks",
          "Slips for boats from 31 to 150 feet in length",
          "4 nautical channels with more than 25 meters wide, nautical buoys and night signaling",
          "Great draft (up to 15 meters deep)",
          "Nautical broadband communication system",
          "Service towers with water and electricity metering according to the demand of your vessel",
          "Vehicular access at the entrance of the dock",
          "Non-slip floating docks for shoeless foot traffic",
          "Night lighting",
          "Electric trolleys for transfer to the interior of the marina",
          "Closed TV circuit",
          "Private warehouses with continuous surveillance",
		  "Exceptional Card allows you to use Banyan Tree Cabo Marqués as a clubhouse"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/4-marina-nautica/marina-nautica-1.jpg",
      "/images/fotos-marina/4-marina-nautica/marina-nautica-2.jpg",
      "/images/fotos-marina/4-marina-nautica/marina-nautica-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Seca",
    sectionDescriptionText: "World-class service for your vessel",
    distribution: "inverse",
    title: "Dry marina",
    content: [
      {
        tag: "ul",
        content: [
            "200 positions for boats and nautical equipment",
            "Maximum length: 38 feet",
            "Maximum beam: 10 feet",
            "Boat transfer and launch equipment",
            "Private ramp",
            "Shaded area with sun protection for your boat",
            "Drinking water and electricity",
            "Closed TV circuit",
            "Night lighting",
			"Private warehouses with night surveillance"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/5-marina-seca/marina-seca-1.jpg",
      "/images/fotos-marina/5-marina-seca/marina-seca-2.jpg",
      "/images/fotos-marina/5-marina-seca/marina-seca-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Otros",
    sectionDescriptionText: "Complementary services at your disposal",
    distribution: "normal",
    title: "Other services",
    content: [
      {
        tag: "ul",
        content: [
          "Warehouse rental",
          "Yacht rental",
	      "Use of the prestigious Banyan Tree Cabo Marqués facilities"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/6-otros-servicios/otros-servicios-1.jpg",
      "/images/fotos-marina/6-otros-servicios/otros-servicios-2.jpg",
      "/images/fotos-marina/6-otros-servicios/otros-servicios-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Membresias",
    sectionDescriptionText: "Become a member of Marina Cabo Marques",
    distribution: "inverse",
    title: "Memberships",
    content: [
      {
        tag: "h3",
        content: "Memberships are ideal if you are looking to use the marina permanently."
      },
      {
        tag: "p",
        content: "It allows you to use all the facilities of the Cabo Marqués Marina, a slip for your boat and nautical equipment in the dry and nautical marina permanently; embarkation, disembarkation and accomodation for you and your guests; use of the beach club, helipad and <strong>Banyan Tree Cabo Marqués</strong>."
      },
      {
        tag: "h3",
        content: "Types of memberships:"
      },
      {
        tag: "ul",
        content: [
          "1 years",
          "5 años",
          "10 años",
          "20 años (Exclusive for owners of Banyan Tree Residences Cabo Marqués and Los Riscos)"
        ]
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reserve"
        }
      },
    ],
    images: [
      "/images/fotos-marina/7-membresias/membresias-1.jpg",
      "/images/fotos-marina/7-membresias/membresias-2.jpg",
      "/images/fotos-marina/7-membresias/membresias-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-Nautica",
    sectionDescriptionText: "Flexible schemes to enjoy the marina",
    distribution: "normal",
    title: "Nautical Marina Certificate",
    content: [
      {
        tag: "h3",
        content: "Ideal to use the nautical marina intermittently."
      },
      {
        tag: "p",
        content: "It allows you the use of embarkation, disembarkation and a spot for your boat for the days you require, access for you and your guests through Cabo Marqués and use of private parking."
      },
      {
        tag: "h3",
        content: "Certificate Types:"
      },
      {
        tag: "ul",
        content: [
          "6 Months",
          "1 Year"
        ]
      },
      {
        tag: "p",
        content: "Each certificate offers you a number of operations and stays during its validity depending on the length of your boat."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reserve"
        }
      },
    ],
    images: [
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-1.jpg",
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-2.jpg",
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-Seca",
    sectionDescriptionText: "Pay-as-you-go options",
    distribution: "inverse",
    title: "Dry Marina Certificate",
    content: [
      {
        tag: "h3",
        content: "Ideal for intermittently using the dry marina or just launching your boat."
      },
      {
        tag: "p",
        content: "This certificate allow you the use of boat dragging, launching ramp, embarkation, disembarkation and a spot of your boat in the nautical or dry marina, for the days you require, access for you and your guests through Cabo Marqués and use of private parking."
      },
      {
        tag: "p",
        content: "<strong>Certificate Types:</strong>"
      },
      {
        tag: "ul",
        content: [
          "Dry Marina Certificate",
          "Dry Marina Certificate + Space Fees"
        ]
      },
      {
        tag: "p",
        content: "The Dry Marina Certificate is annual and you only pay for the operations you carry out."
      },
      {
        tag: "p",
        content: "The certificate of dry marina + space fees is annual and has a number of operations included at a preferential rate."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reserve"
        }
      },
    ],
    images: [
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-1.jpg",
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-2.jpg",
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Embarcaciones-Transito",
    sectionDescriptionText: "The best marina for your stay in Acapulco",
    distribution: "normal",
    title: "Vessels in transit",
    content: [
      {
        tag: "h3",
        content: "Enjoy and rest in the most exclusive destination of your nautical journey."
      },
      {
        tag: "p",
        content: "Make the most of the best nautical service in Marina Cabo Marqués, a top Hotel and Spa, <strong>Banyan Tree Cabo Marques</strong>"
      },
      {
        tag: "ul",
        content: [
          "Capacity up to 150 feet in length",
          "Electric power and drinking water at the foot of the dock",
          "Security and surveillance 24 hours a day",
          "Shelter port",
		  "Discounts on lodging, food and beverages at Banyan Tree Cabo Marqués"
        ]
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reserve"
        }
      },
    ],
    images: [
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-1.jpg",
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-2.jpg",
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-NEB",
    sectionDescriptionText: "Live un unforgettable experience at Cabo Marques",
    distribution: "inverse",
    title: "NE&B Certificate",
    content: [
      {
        tag: "h3",
        content: "Create a romantic moment and a unique nautical experience at Banyan Tree Cabo Marqués."
      },
      {
        tag: "p",
        content: "With the Navigate, Enjoy and Buy (NE&B) certificate, enjoy relaxation and harmony at Banyan Tree SPA, contemporary Thai food and the most spectacular sunsets in Acapulco with nautical access to the Cabo Marqués Marina."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "I'm interested"
        }
      },
    ],
    images: [
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-1.jpg",
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-2.jpg",
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Tarifas",
    sectionDescriptionText: "Rates",
    distribution: "normal",
    title: "Marina Cabo Marqués Rates",
    content: [
      {
        tag: "h3",
        content: "Discover the different rate options."
      },
      {
        tag: "p",
        content: "We put at your disposal a list of services and their respective rates."
      },
      {
        tag: "h3",
        content: "Ask our consultants for special offers."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Contact Us"
        }
      },
    ],
    images: [
      "/images/fotos-marina/12-tarifas/tarifas-1.jpg",
      "/images/fotos-marina/12-tarifas/tarifas-2.jpg",
      "/images/fotos-marina/12-tarifas/tarifas-3.jpg"
    ]
  },
]