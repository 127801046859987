import React from "react";

import Layout from "../../components/Layout";
import CarouselUi from "../../components/Layout/Carousel";
import CarouselSection from "../../components/Layout/CarouselSection";
import ArticleContent from "../../components/Layout/ArticleContent";
import SectionDescription from "../../components/Layout/SectionDescription";
import ButtonTo from "../../components/Layout/ButtonTo";
import Form from "../../components/Contact/Form";
import ArticleContentTitleH2 from "../../components/Layout/ArticleContentTitleH2";
import ArticleContentTitleH3 from "../../components/Layout/ArticleContentTitleH3";
import { dataSectionsEn } from "../../data/en";
import Helmet from "react-helmet";

const IndexPage = () => {

  return (
    <Layout english={true}>
      <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>Marina Cabo Marqués – La única marina en la zona Diamante de Acapulco</title>
          <meta name="description" content="La única marina en la zona Diamante de Acapulco" />
      </Helmet>
      <div className="custom-carousel"> <CarouselUi /> </div>
      <Form english={true}/>
      <section>
        {dataSectionsEn.map((dataSection, index) => ( <>
        <SectionDescription id={dataSection.sectionDescriptionId} text={dataSection.sectionDescriptionText} />
        <ArticleContent>
        { dataSection.distribution === "normal" && <div className="custom-carousel"> <CarouselSection images={ dataSection.images } /> </div> }
          <div className="flex justify-start items-center">
            <div className="px-5 md:px-14 py-8">
              <ArticleContentTitleH2 title={dataSection.title} />
              {dataSection.content.map((content, index) => ( <>
                { content.tag === "p" && 
                  <p key={index} className="text-[#676767] pb-6" dangerouslySetInnerHTML={{ __html: content.content }} /> 
                }
                { content.tag === "h3" && 
                  <ArticleContentTitleH3 key={index} title={content.content} />
                }
                { content.tag === "ul" &&
                  <ul className="list-disc text-[#676767]">
                    {content.content.map((content, index) => ( <>
                      <li key={index}> <span className="text-[#676767] pb-6"> {content} </span> </li>
                    </> ))}
                  </ul>
                }
                { content.tag === "contactButton" && 
                  <div className="mt-6"> <ButtonTo to={content.content.to} text={content.content.text} /> </div>
                }
              </> ))}
            </div>
          </div>
          { dataSection.distribution === "inverse" && <div className="custom-carousel"> <CarouselSection images={ dataSection.images }/> </div> }
        </ArticleContent>
        </> ) ) }
      </section>
    </Layout>
  );
};

export default IndexPage;